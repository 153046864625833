<template>
  <div class="container">
     <div class="title">修改密码</div>
     <div class="from">
        <div class="inpbox">
            <div class="text">请输入旧密码：</div>
            <div class="inp">
                <el-input v-model="oldPwd"  size="large" @blur="blurOld" placeholder="请输入旧密码" clearable />
            </div>
            <div class="tip" ><span v-if="oldp">*密码错误  请重新输入</span></div>
        </div>
        <div class="inpbox ts">
            <div class="text">请输入新密码：</div>
            <div class="inp">
                <el-input v-model="newPwd"  size="large"  @blur="blurNew" placeholder="请输入新密码" clearable />
                <div class="tp">请输入16位大小写英文字母，数组的组合</div>
            </div>
            <div class="tip" ><span v-if="newp">*格式错误  请重新输入</span></div>
        </div>
        <div class="inpbox">
            <div class="text">请再次输入密码：</div>
            <div class="inp">
                <el-input v-model="confirmPwd"  size="large"  @blur="blurConfirm" placeholder="请再次输入密码" clearable />
            </div>
            <div class="tip" ><span v-if="confrimp">*两次密码输入不一致请检查后重新输入</span></div>
        </div>
     </div>
     <div class="btnbox">
        <div @click="change" class="change">保存</div>
        <div @click="close" class="close">取消</div>
     </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
import { ElMessage } from 'element-plus'
import { Model } from '@/api/model'
const check = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{2,16}$/
const api = new Model()
export default {
  setup (props, context) {
    const oldPwd = ref('')
    const newPwd = ref('')
    const confirmPwd = ref('')
    const oldp = ref(false)
    const newp = ref(false)
    const confrimp = ref(false)
    const close = () => {
      context.emit('close')
    }
    const change = () => {
      // 调用接口修改密码
      console.log(oldPwd.value !== '', newPwd.value !== '', confirmPwd.value !== '', !confrimp.value, !newp.value, !oldp.value)
      if (oldPwd.value !== '' && newPwd.value !== '' && confirmPwd.value !== '' && !confrimp.value && !newp.value && !oldp.value) {
        context.emit('changePwd')
        api.changePwd({ userName: localStorage.user, passWord: newPwd.value }).then(res => {
          console.log(res)
          ElMessage.success('修改成功')
        })
      } else {
        ElMessage.error('格式错误吗，请检查表单中的格式')
      }
    }
    const blurOld = () => {
      if (oldPwd.value.trim() !== localStorage.pwd) {
        oldp.value = true
      } else {
        oldp.value = false
      }
    }
    const blurNew = () => {
      console.log(check.test(newPwd.value))
      if (!check.test(newPwd.value)) {
        newp.value = true
      } else {
        newp.value = false
      }
    }
    const blurConfirm = () => {
      if (newPwd.value !== confirmPwd.value) {
        confrimp.value = true
      } else {
        confrimp.value = false
      }
    }
    return { oldPwd, newPwd, confirmPwd, close, change, oldp, newp, confrimp, blurConfirm, blurNew, blurOld }
  }
}
</script>

<style lang="scss" scoped>
.container{
    font-size: .22rem;
    width: 9.55rem;
    height: 5.73rem;
    background:#fff;
    border-radius: .14rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title{
        font-size:.28rem;
        font-weight: 500;
    }
    .from{
        margin-top:.40rem;
    }
    .ts{
        margin-bottom: .15rem !important;
    }
    .inpbox{
        display: flex;
        flex-direction: row;
        margin-bottom:.35rem;
        align-items: center;
        .text{
            width: 1.60rem;
            text-align: right;
            padding-right: .15rem;
            font-size: .20rem;
        }
        .tip{
            width:1.71rem;
            color:red;
            padding-left: .15rem;
        }
        .inp{
            width: 3.94rem;
        }
        .tp{
            font-size: .14rem;
            color: #999;
        }
    }
    .btnbox{
            width: 100%;
            margin-top: .80rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .change{
                background:#F58245
            }
            .close{
                margin-left: .66rem;
                background: #FE2D2D;
            }
            .close,
            .change{
                width: 1.76rem;
                height: .53rem;
                border-radius:.27rem;
                color:#fff;
                text-align: center;
                line-height: .53rem;
            }
        }
}
</style>
