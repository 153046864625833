import { instance } from './http'

export class Model {
  indexList (str = '') {
    return instance.post(`home/list?${str}`)
  }

  getCount (str) {
    return instance.post(`home/getCount?${str}`)
  }

  login (data) {
    return instance.post('home/login', data)
  }

  changePwd (data) {
    return instance.post('home/login', data)
  }
}
