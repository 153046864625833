<template>
  <div class="container">
    <div class="title">筛选</div>
     <div class="filter">
        <div style="width:1.32rem" class="actionTime">
        <el-select v-model="SearchData.flag" clearable placeholder="激活时间"  size="large">
        <el-option
          v-for="item in actionlist.data"
          :key="item.value"
             :label="item.label"
             :value="item.value" />
        </el-select>
        </div>
         <div class="demo-range" style="width:3.20rem">
         <el-date-picker size="large" style="width:94%"
            v-model="SearchData.scopeDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
        />
        </div>
        <div class="State">
            设备状态：
            <div style="width:1.36rem">
            <el-select v-model="SearchData.onlineStatus" clearable placeholder="设备状态"  size="large">
            <el-option
             v-for="item in devStateAry.data"
             :key="item.value"
             :label="item.label"
             :value="item.value" />
            </el-select>
             </div>
        </div>
        <div class="State">
            套餐状态：
            <div style="width:1.36rem">
            <el-select v-model="SearchData.qmStatus" clearable placeholder="套餐状态"  size="large">
            <el-option
             v-for="item in qmStateAry.data"
             :key="item.value"
             :label="item.label"
             :value="item.value" />
            </el-select>
             </div>
        </div>
        <div class="input">
            <el-input v-model="SearchData.deviceOrPhone" placeholder="输入设备ID，绑定手机号" clearable  size="large"/>
        </div>
        <div class="btn" @click="submit">
            搜索
        </div>
     </div>
  </div>
</template>

<script>
import { reactive } from 'vue-demi'
export default {
  setup (prop, context) {
    const devStateAry = reactive({
      data: [
        {
          label: '离线',
          value: '0'
        },
        {
          label: '在线',
          value: '1'
        }
      ]
    })
    const qmStateAry = reactive({
      data: [
        {
          label: '已过期',
          value: '0'
        },
        {
          label: '可用',
          value: '1'
        },
        {
          label: '未激活',
          value: '2'
        }
      ]
    })
    const actionlist = reactive({
      data: [
        {
          label: '激活时间',
          value: '1'
        },
        {
          label: '创建时间',
          value: '2'
        },
        {
          label: '套餐时间',
          value: '3'
        }
      ]
    })
    const SearchData = reactive({ scopeDate: ['', ''], onlineStatus: '', qmStatus: '', deviceOrPhone: '', flag: '' })
    const submit = () => {
      if (!SearchData.scopeDate[SearchData.scopeDate.length - 1]) {
        console.log(1)
        SearchData.startTime = ''
        SearchData.endTime = ''
        let temp = {}
        temp = { ...SearchData }
        if (SearchData.qmStatus === '2') {
          temp.qmStatus = ''
        }
        context.emit('Search', temp)
        return
      }
      if (SearchData.scopeDate[0] !== '' && SearchData.scopeDate[1] !== '') {
        SearchData.startTime = `${SearchData.scopeDate[0].getFullYear()}-${SearchData.scopeDate[0].getMonth() + 1}-${SearchData.scopeDate[0].getDate()}`
        SearchData.endTime = `${SearchData.scopeDate[1].getFullYear()}-${SearchData.scopeDate[1].getMonth() + 1}-${SearchData.scopeDate[1].getDate()}`
      }
      let cstemp = {}
      cstemp = { ...SearchData }
      if (SearchData.qmStatus === '2') {
        cstemp.qmStatus = ''
      }
      context.emit('Search', SearchData)
    }
    return { devStateAry, qmStateAry, actionlist, submit, SearchData }
  }
}
</script>

<style lang="scss" scoped>
    .container{
        background: #fff;
        height: 1.28rem;
        padding: .10rem .28rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        font-size: .22rem;
        .title{
            font-size: .22rem;
            font-weight: 500;
        }
        .filter{
            display: flex;
            margin-top: .21rem;
        }
        .actionTime{
            width: 1.32rem;
            margin-left: 1.20rem;
        }
        .demo-range{
             width: 3.40rem;
            /* overflow: hidden; */
             margin-left: .20rem;
        }
        .State{
            display: flex;
            margin-left: .20rem;
            align-items: center;
        }
        .input{
            width: 3.15rem;margin-left: .50rem;
        }
    }
    @import '../assets/css/common.scss'
</style>
