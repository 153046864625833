<template>
  <div class="container">
    <div class="content">
        <div class="title">设备信息</div>
        <div class="info">
            <div class="flex">
                <div>设备ID：{{Process(showData.data.deviceId)}}</div>
                <div>手机号： <span v-if="isMobile">{{showData.data.mobile}}</span><span v-if="!isMobile">{{ProcessMobile(showData.data.mobile)}}</span> <span @click="showMobile" style="color:#2C78D2;">显示</span></div>
                <div>mac：{{Process(showData.data.mac)}}</div>
            </div>
            <div class="flex">
                <div>激活时间：{{Process(showData.data.activeTime)}}</div>
                <div>设备型号：{{Process(showData.data.deviceId)}}</div>
                <div>固件版本：{{Process(showData.data.fwVer)}}</div>
            </div>
            <div class="flex">
                <div>设备状态：{{Processonline(showData.data.online)}}</div>
                <div>激活状态：{{ProcesActiveStatus(showData.data.activeStatus)}}</div>
                <div>当天唤醒：{{ProcesActiveStatus(showData.data.isAwake)}}</div>
            </div>
            <div class="flex">
            </div>
        </div>
    </div>
    <div class="content">
        <div class="title">套餐信息</div>
        <div class="info">
            <div class="flex">
                <div>订单号：{{Process(showData.data.id)}}</div>
                <div>套餐状态：{{ProcessQm(showData.data.qm)}}</div>
            </div>
            <div class="flex">
                <div>开通时间：{{Process(showData.data.qmStart)}}</div>
                <div>关闭时间：{{Process(showData.data.qmEnd)}}</div>
            </div>
        </div>
    </div>
    <div class="exit" @click="exit">退出</div>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
const useProcessData = () => {
  function ProcessQm (str) {
    if (str === '0') {
      return '已过期'
    } else if (str === '1') {
      return '可用'
    } else {
      return '未激活'
    }
  }
  function ProcessMobile (str) {
    const pwd = '****'
    if (str) {
      // 获取前三位
      const front = str.substring(0, 3)
      // 获取后四位
      const after = str.substring(str.length - 4, str.length)
      // 拼接返回
      return front + pwd + after
    } else {
      return '-（传输失败等）'
    }
  }
  function Processonline (str) {
    if (str === '0') {
      return '离线'
    } else if (str === '1') {
      return '在线'
    } else {
      return '-'
    }
  }
  function Process (str) {
    if (str) {
      return str
    } else {
      return '-'
    }
  }
  function ProcesActiveStatus (str) {
    if (str === '0') {
      return '否'
    } else if (str === '1') {
      return '是'
    } else {
      return '-'
    }
  }
  return { ProcessQm, ProcessMobile, Processonline, Process, ProcesActiveStatus }
}
export default {
  props: ['showData'],
  setup (props, context) {
    const isMobile = ref(false)
    const { ProcessQm, ProcessMobile, Processonline, Process, ProcesActiveStatus } = useProcessData()
    const exit = () => {
      console.log(props.showData)
      context.emit('exit')
    }
    const showMobile = () => {
      isMobile.value = !isMobile.value
    }
    return { exit, ProcessMobile, ProcessQm, showMobile, isMobile, Processonline, Process, ProcesActiveStatus }
  }
}
</script>

<style lang="scss" scoped>

.container{
    font-size: .22rem;
    width: 12.71rem;
    height: 7.68rem;
    background: #FFFFFF;
    box-shadow: 0px 0px .08rem .01rem rgba(0,0,0,0.1300);
    border-radius: .22rem;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    padding: .70rem;
    box-sizing: border-box;
    .content{
        margin-bottom: .05rem;
        .title{
            font-size: .24rem;
            font-weight: 500;
            margin-bottom: .25rem;
        }
        .flex{
            display: flex;
            justify-content: space-between;
            margin-bottom: .20rem;
        }
        .flex div{
            flex: 1;
        }
    }
    .exit{
        margin: auto;
        width: 2.50rem;
        height: .50rem;
        background: #FE2D2D;
        color: #fff;
        line-height: .50rem;
        text-align: center;
    }
}

</style>
