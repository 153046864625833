<template>
  <div class="container">
     <div class="maskExit">
          <div class="title">退出登录</div>
          <div class="msg">确认退出登陆吗？</div>
          <div class="exitbtn">
            <div class="comfirm" @click="tempfun('comfirm')">确认退出</div>
            <div class="close" @click="tempfun('close')">取消</div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  setup (props, context) {
    const tempfun = (str) => {
      context.emit(str)
    }
    return { tempfun }
  }
}
</script>

<style lang="scss" scoped>
.container{
  font-size: .22rem;
}
  .maskExit{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 4.36rem;
    height: 2.72rem;
    background: #FFFFFF;
    border-radius: .18rem;
    z-index: 1000;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around
  }
  .title{
    font-weight: 500;
    font-size: .34rem;
  }
  .msg{
    font-weight: 500;
    font-size: .26rem;
  }
  .exitbtn{
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .comfirm{
    width: 1.56rem;
    height: .58rem;
    background: #F58245;
    border-radius: .29rem;
    cursor: pointer;
    line-height: .58rem;
    text-align: center;
    color: #FFF;
  }
  .close{
    width: 1.56rem;
    height: .58rem;
    background: #ccc;
    border-radius: .29rem;
    cursor: pointer;
    line-height: .58rem;
    text-align: center;
    color: #FFF ;
  }
</style>
