<template>
  <div class="container">
    <div class="left con">
      <div class="title">设备列表</div>
      <div class="week">
        近七天：
        <div class="input">
             <el-select v-model="acitonFiler.SevenDayVls" clearable placeholder="激活"  size="large">
            <el-option
             v-for="item in actionlist.data"
             :key="item.value"
             :label="item.label"
             :value="item.value" />
            </el-select>
        </div>
      </div>
      <div class="count">数量：{{SevenNumber}}</div>
      <div class="btn" @click="SevenQuery">查询</div>
    </div>
    <div class="right con">
      <div class="input ">
         <el-select v-model="acitonFiler.Year" clearable placeholder="年份"  size="large">
            <el-option
             v-for="item in Yearoptions.data"
             :key="item.value"
             :label="item.label"
             :value="item.value" />
            </el-select>
      </div>
      <div class="input inp">
         <el-select v-model="acitonFiler.Month" clearable placeholder="月份"  size="large">
            <el-option
             v-for="item in Monthoptions.data"
             :key="item.value"
             :label="item.value"
             :value="item.value" />
            </el-select>
      </div>
      <div class="input inp">
         <el-select v-model="acitonFiler.value" clearable placeholder="激活"  size="large">
            <el-option
             v-for="item in actionlist.data"
             :key="item.value"
             :label="item.label"
             :value="item.value" />
            </el-select>
      </div>
      <div class="count">数量：{{YMNumber}}</div>
      <div class="btn" @click="Query">查询</div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue-demi'
import { Model } from '@/api/model'
import { ElMessage } from 'element-plus'
const api = new Model()
export default {
  setup () {
    const SevenNumber = ref('')
    const YMNumber = ref('')
    const Yearoptions = reactive({ data: [{ value: 2018 }, { value: 2019 }, { value: 2020 }, { value: 2021 }, { value: 2022 }] })
    const Monthoptions = reactive({ data: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }] })
    const acitonFiler = reactive({ Year: '', Month: '', SevenDayVls: '', value: '' })
    const actionlist = reactive({
      data: [
        {
          label: '已激活',
          value: '1'
        },
        {
          label: '未激活',
          value: '2'
        },
        {
          label: '已过期',
          value: '3'
        }
      ]
    })
    onMounted(() => {
      // 获取当前年份
    })
    const SevenQuery = () => {
      const str = `sevenDay="7"&activeStatus=${acitonFiler.SevenDayVls}`
      api.getCount(str).then(res => {
        const { code, data, msg } = res.data
        if (code === '200') {
          SevenNumber.value = data
        } else {
          ElMessage.error(msg)
        }
      })
    }
    const Query = () => {
      const ym = `${acitonFiler.Year}-${acitonFiler.Month}`
      const str = `dayMonth=${ym}&activeStatus=${acitonFiler.value}`
      api.getCount(str).then(res => {
        const { code, data, msg } = res.data
        if (code === '200') {
          YMNumber.value = data
        } else {
          ElMessage.error(msg)
        }
      })
    }
    return { actionlist, acitonFiler, SevenQuery, SevenNumber, YMNumber, Query, Yearoptions, Monthoptions }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    .con{
    display: flex;
    height: .85rem;
    flex-direction: row;
    align-items: center;
    }
    .left{
         margin-left: .44rem;
         .title{
            font-size: .22rem;
            font-weight: 500;
         }
         .week{
        margin-left: .79rem;
           display: flex;
           font-size: .20rem;
           color: #666;
           align-items: center;
         }
    }
    .right{
        margin-left: 1.62rem;
    }
    .input{
        width: 1.35rem;
        display: flex
    }
    .count{
         color: #666;
         font-size: .20rem;
         width: 1.40rem;
         margin-left: .20rem;
    }
    .inp{
        margin-left: .20rem;
    }
  }
  @import '../assets/css/common.scss';
</style>
