<template>
   <div class="container">
        <div class="title">U点音箱管理系统</div>
        <div class="state" @click.stop="isOpen">
            <div size="large" style="color:#fff;font-size:.30rem"    trigger="click"   >
            <span class="el-dropdown-link">
              管理员账号
            <el-icon class="el-icon--right">
             <CaretBottom v-show="!Open"/>
             <CaretTop  v-show="Open"/>
            </el-icon>
            </span>
             </div>
            <div class="itembox"  ref="dorpdownRef"   v-show="Open">
          <div class="item" @click="ChangePwd">修改密码</div>
           <div class="line"></div>
          <div class="item" @click="exit">退出登录</div>
        </div>
        </div>
   </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue-demi'

export default {
  emits: ['exit'], // 声明自定义事件
  setup (props, context) {
    const Open = ref(false)
    const dorpdownRef = ref()
    const isOpen = () => {
      Open.value = !Open.value
    }
    const haedler = (e) => {
      console.log(e)
      if (Open.value) {
      // if (!dorpdownRef.value.contains(e.target) && Open.value) {
        Open.value = false
      }
    }
    onMounted(() => {
      document.addEventListener('click', haedler)
    })
    onUnmounted(() => {
      document.removeEventListener('click', haedler)
    })
    const exit = () => {
      context.emit('exit')
    }
    const ChangePwd = () => {
      context.emit('ChangePwd')
    }
    return {
      isOpen, Open, dorpdownRef, exit, ChangePwd
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
    font-size: .22rem;
    height: .85rem;
    background: #F58245;
    width: 100%;
    display: flex;
    color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    padding: 0 .28rem;
    box-sizing: border-box;
    .title{
        font-size: .30rem;
        font-weight: 500;
    }
    .itembox{
          position: absolute;
    // margin-left: 16.63rem;
    width: 2.29rem;
    height: 1.38rem;
    background: #F58245;
    /* background: rebeccapurple; */
       top: .65rem;
    z-index: 9999;
        display: flex;
    flex-direction: column;
    align-items: center;
    right: 0;
    }
    .item{
      height:.68rem;
      width: 100%;
      line-height: .68rem;
    text-align: center;
    cursor: pointer;
    // display: none;
    }
    .line{
          display: flex;
    flex-direction: column;
    align-items: center;
    }
}

</style>
