// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const { isLogin } = localStorage // 从缓存中获取数据
      isLogin ? next() : next({ name: 'login' })
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
    // beforeEnter: (to, from, next) => { //  进入此路由前触发
    //   const { isLogin } = localStorage // 从缓存中获取数据
    //   isLogin ? next({ name: 'home' }) : next()
    // }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach((to, from, next) => {
//   const { isLogin } = localStorage
//   const { name } = to
//   const isLoginView = (name === 'login')
//   isLogin || isLoginView ? next() : next({ name: 'login' })
// })

export default router
