<template>
  <div class="home">
    <home-header  @exit="exit" @ChangePwd="ChangePwd"></home-header>
    <div class="content">
      <div class="title">设备管理</div>
      <home-filter  @Search="Search"></home-filter>
      <div class="table">
          <div class="table-filter">
             <table-filter></table-filter>
          </div>
          <div class="table-tb">
            <home-table :tableData="tableData.data.records"  @isShow="isShow"></home-table>
          </div>
          <div class="table-page">
            <div> <el-button type="warning" @click="outExice">Exice导出</el-button></div>
            <div class="paging">
              <span style="font-size:.14rem">共{{tableData.data.total}}条</span>
             <el-pagination  ref="RefPage" v-model:currentPage="tableData.data.current"  :page-size="sizeCount"
             :small="small"
             :background="background"
             layout=" slot, prev, pager, next, jumper "
             :total="tableData.data.total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            >
            <div class="first-pager"  >
              <el-select v-model="sizeCount" class="m-2" placeholder="Select"  @change="handleCurrentChange">
                <el-option
                 v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
                </el-select>
            </div>
            </el-pagination>
            <!-- <el-input v-model="input" placeholder="Please input" style="width:.33rem"  @blur="blurChange"/> -->
             <div class="jumper "> 跳转至   </div>
            </div>
          </div>
      </div>
    </div>
    <div class="msak" v-if="isExit || isChangePwd || isInfo">
       <exit-mask @close='close' @comfirm="comfirm"  v-if="isExit"></exit-mask>
       <change-mask v-if="isChangePwd" @close='closeM' @changePwd='closeM'></change-mask>
       <info-mask v-if="isInfo" :showData = "infoData" @exit="oushow"></info-mask>
    </div>
  </div>
</template>

<script>
import { Model } from '@/api/model'
import { onMounted, reactive, ref } from '@vue/runtime-core'
import HomeHeader from '@/components/HomeHeader.vue'
import HomeFilter from '@/components/HomeFilter.vue'
import HomeTable from '@/components/HomeTable.vue'
import TableFilter from '@/components/TableFilter.vue'
import { useRouter } from 'vue-router'
import ExitMask from '@/components/mask/ExitMask.vue'
import ChangeMask from '@/components/mask/ChangeMask.vue'
import InfoMask from '@/components/mask/InfoMask.vue'
import * as XLSX from 'xlsx'
const api = new Model()
export default {
  name: 'HomeView',
  components: {
    HomeHeader,
    HomeFilter,
    HomeTable,
    TableFilter,
    ExitMask,
    ChangeMask,
    InfoMask
  },
  setup () {
    const input = ref(1)
    const RefPage = ref() // 获取组件
    const options = [
      {
        value: 10,
        label: '10台/页'
      },
      {
        value: 30,
        label: '30台/页'
      },
      {
        value: 50,
        label: '50台/页'
      },
      {
        value: 80,
        label: '80台/页'
      },
      {
        value: 100,
        label: '100台/页'
      }
    ]
    const isExit = ref(false)
    const tableData = reactive({ data: [] })
    const router = useRouter()
    const isChangePwd = ref(false)
    let HomeFiler = null // 存储搜索状态
    const isInfo = ref(false)
    const infoData = reactive({ data: [] })
    // let tableFiler = null
    const sizeCount = ref(10)
    onMounted(() => {
      api.indexList('current=1&size=10').then(res => {
        tableData.data = res.data.data
      })
      // window.addEventListener('beforeunload', localStorage.clear()) // 页面关闭时触发
    })
    const exit = () => {
      isExit.value = true
    }
    const ChangePwd = () => {
      isChangePwd.value = true
    }
    const close = () => {
      isExit.value = false
    }
    const closeM = () => {
      isChangePwd.value = false
    }
    const comfirm = () => {
      localStorage.clear()
      router.push({ name: 'login' })
    }
    // 页面跳转
    const blurChange = () => {
      // 进行页面跳转
      console.log(RefPage.handleCurrentChange)
      RefPage.value.handleCurrentChange(input)
    }
    const handleCurrentChange = () => {
      const currentPage = tableData.data.current
      let str
      if (HomeFiler !== null) {
        str = `current=${currentPage}&size=${sizeCount.value}&deviceOrPhone=${HomeFiler.deviceOrPhone}&flag=${HomeFiler.flag}&startTime=${HomeFiler.startTime}&endTime=${HomeFiler.endTime}&onlineStatus=${HomeFiler.onlineStatus}&qmStatus=${HomeFiler.qmStatus}`
      } else {
        str = `current=${currentPage}&size=${sizeCount.value}`
      }
      console.log(str)
      api.indexList(str).then(res => {
        tableData.data = res.data.data
      })
    }
    const handleSizeChange = (val) => {
      sizeCount.value = val
      handleCurrentChange()
    }
    const Search = (ret) => {
      console.log(ret)
      HomeFiler = ret
      tableData.data.current = 1 // 搜索回到第一页
      const str = `current=1&size=10&deviceOrPhone=${ret.deviceOrPhone}&flag=${ret.flag}&startTime=${ret.startTime}&endTime=${ret.endTime}&onlineStatus=${ret.onlineStatus}&qmStatus=${ret.qmStatus}`
      api.indexList(str).then(res => {
        tableData.data = res.data.data
      })
    }
    const isShow = (str) => {
      isInfo.value = true
      console.log(str)
      infoData.data = str
    }
    const oushow = () => {
      isInfo.value = false
    }
    const outExice = () => {
      // 拿到全部数据
      let str
      if (HomeFiler !== null) {
        str = `deviceOrPhone=${HomeFiler.deviceOrPhone}&flag=${HomeFiler.flag}&startTime=${HomeFiler.startTime}&endTime=${HomeFiler.endTime}&onlineStatus=${HomeFiler.onlineStatus}&qmStatus=${HomeFiler.qmStatus}`
      } else {
        str = ''
      }
      api.indexList(str).then(res => {
        console.log(res.data.data.records)
        // 新建空workbook，然后加入worksheet
        const ws = XLSX.utils.json_to_sheet(res.data.data.records)
        // 设置每列的列宽，10代表10个字符，注意中文占2个字符
        ws['!cols'] = [
          { wch: 10 },
          { wch: 30 },
          { wch: 25 }
        ]
        // 新建book
        const wb = XLSX.utils.book_new()
        // 生成xlsx文件(book,sheet数据,sheet命名)
        XLSX.utils.book_append_sheet(wb, ws, '数据详情') // 表格名
        // 写文件(book,xlsx文件名称)
        XLSX.writeFile(wb, '列表详情.xlsx') // 文件名
      })
      // 导出
    }
    return { tableData, isExit, exit, close, comfirm, handleSizeChange, Search, ChangePwd, isChangePwd, closeM, isInfo, isShow, infoData, oushow, outExice, handleCurrentChange, options, sizeCount, RefPage, blurChange, input }
  }
}
</script>

<style lang="scss" scoped>
.jumper{
  font-size: 0.14rem;
    position: absolute;
    display: flex;
    white-space: nowrap;
    background: #fff;
    line-height: 32px;
    right: 69px;
    text-align: right;
    width: 54px;
}
.first-pager{
  height: 1.03rem;
  align-items: center;
  display: flex;
}
  .home{
    width: 19.20rem;
  }
  .content{
    background: #F0F0F0;
    top: .85rem;
    left: 0;
    // position: absolute;
    // right: 0;
    // bottom: 0;
    width: 100%;
    padding: 0.28rem;
    overflow: hidden;
    box-sizing: border-box;
    .title{
      font-size: .30rem;
      font-weight: 500;
      margin-bottom: .25rem;
    }
    .table{
        height: 100%;
        margin-top:.15rem;
        background: #fff;
        .table-tb{
          height:auto;
        }
        .table-page{
        height: .86rem;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 .21rem;
        box-sizing: border-box;
        justify-content: space-between;
        .paging{
          display: flex;
          align-items: center;
          position: relative;
        }
        }
    }
  }
  .msak{
    background: rgba($color: #000000, $alpha: 0.3);
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
</style>
