<template>
  <div class="container" >
    <el-table :data="tableData" style="width: 100%;height=100% " :header-cell-style="{background:'#FAFAFA',color:'#000',height:'.53rem',fontSize:'.14rem'}" >
    <el-table-column prop="deviceId" label="设备id" />
    <el-table-column label="手机号码" >
      <template #default="scope">
          <span v-if="scope.row.mobile">{{ ProcessMobile(scope.row.mobile) }}</span>
          <span v-else  class="colorF58245" >{{ ProcessMobile(scope.row.mobile) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" label="套餐开通时间" >
       <template #default="scope">
          <span v-if="scope.row.qmStart">{{ ProcessCreateTime(scope.row.qmStart) }}</span>
          <span v-else  class="colorF58245" >{{ ProcessCreateTime(scope.row.qmStart) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="activeTime" label="激活时间" >
      <template #default="scope">
          <span v-if="scope.row.activeTime">{{ Process(scope.row.activeTime) }}</span>
          <span v-else   >{{ Process(scope.row.activeTime) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="onlineTime" label="最后一次上线日期" >
      <template #default="scope">
          <span v-if="scope.row.onlineTime">{{ Process(scope.row.onlineTime) }}</span>
          <span v-else  >{{ Process(scope.row.onlineTime) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="onlineCount" label="本月在线时长" >
      <template #default="scope">
          <span v-if="scope.row.onlineCount">{{ ProcessonlineTime(scope.row.onlineCount) }}</span>
          <span v-else  >{{ ProcessonlineTime(scope.row.onlineCount) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="online" label="设备状态" >
      <template #default="scope">
          <span v-if="scope.row.online" :class="{'color0F952E':scope.row.online === '1'}">{{ Processonline(scope.row.online) }}</span>
          <span v-else  >{{ Processonline(scope.row.online) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="activeStatus" label="是否激活" >
      <template #default="scope">
          <span v-if="scope.row.activeStatus" :class="{'colorF58245':scope.row.activeStatus === '1'}">{{ ProcesActiveStatus(scope.row.activeStatus) }}</span>
          <span v-else  >{{ ProcesActiveStatus(scope.row.activeStatus) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="qm" label="套餐状态" >
      <template #default="scope">
          <span v-if="scope.row.qm"  :class="{'colorF58245':scope.row.qm === '1', 'colorFE2C2C':scope.row.qm === '0'}" >{{ ProcessQm(scope.row.qm) }}</span>
          <span v-else  >{{ ProcessQm(scope.row.qm) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="isAwake" label="当天是否唤醒">
       <template #default="scope">
          <span v-if="scope.row.isAwake"   >{{ ProcesActiveStatus(scope.row.isAwake) }}</span>
          <span v-else >{{ ProcesActiveStatus(scope.row.isAwake) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="address" label="操作" >
      <template #default="scope">
          <span class="color2C78D2" @click="ProcessInfo(scope.row)" :data-info="scope.row">详情</span>
      </template>
    </el-table-column>
  </el-table>
  </div>
</template>

<script>
const useProcessData = () => {
  function ProcessMobile (str) {
    const pwd = '****'
    if (str) {
      // 获取前三位
      const front = str.substring(0, 3)
      // 获取后四位
      const after = str.substring(str.length - 4, str.length)
      // 拼接返回
      return front + pwd + after
    } else {
      return '-（传输失败等）'
    }
  }
  function ProcessCreateTime (str) {
    if (str) {
      return str
    } else {
      return '-'
    }
  }
  function Process (str) {
    if (str) {
      return str
    } else {
      return '-'
    }
  }
  function ProcessonlineTime (str) {
    if (str) {
      const tempf = parseInt(parseInt(str) / 60) // 得到分钟
      const temps = parseInt(tempf / 60)
      if (temps > 0) {
        const f = tempf - temps * 60
        if (f > 0) {
          return temps + '时' + f + '分'
        } else {
          return temps + '时'
        }
      } else {
        return tempf + '分'
      }
    } else {
      return '-'
    }
  }
  function ProcessQm (str) {
    if (str === '0') {
      return '已过期'
    } else if (str === '1') {
      return '可用'
    } else {
      return '未激活'
    }
  }
  function ProcesActiveStatus (str) {
    if (str === '0') {
      return '否'
    } else if (str === '1') {
      return '是'
    } else {
      return '-'
    }
  }
  function Processonline (str) {
    if (str === '0') {
      return '离线'
    } else if (str === '1') {
      return '在线'
    } else {
      return '-'
    }
  }
  return { ProcessMobile, ProcessCreateTime, Process, Processonline, ProcessonlineTime, ProcesActiveStatus, ProcessQm }
}
export default {
  props: ['tableData'],
  setup (props, context) {
    function ProcessInfo (str) {
      context.emit('isShow', str)
    }
    const { ProcessMobile, ProcessCreateTime, Process, Processonline, ProcessonlineTime, ProcesActiveStatus, ProcessQm } = useProcessData()
    return { ProcessMobile, ProcessCreateTime, Process, Processonline, ProcessonlineTime, ProcesActiveStatus, ProcessQm, ProcessInfo }
  }
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
}
.colorF58245{
  color: #F58245 ;
}
.color0F952E{
  color: #0F952E;
}
.colorFE2C2C{
  color: #FE2C2C;
}
.color2C78D2{
  color: #2C78D2;
}
</style>
