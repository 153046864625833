<template>
    <router-view></router-view>
</template>

<style lang="scss" >
.el-range-editor--large .el-range-input {
    font-size: .14rem !important;
}
.el-range-editor--large.el-input__wrapper {
    /* font-size: .14rem; */
    height: 0.4rem  !important;
}
.el-input--large {
    --el-input-height: .40rem !important;
    font-size: .14rem !important;
    }
</style>
